import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Step_two.svg"
import STEP_THREE_CONTENT from "../../images/StepThreeContent.svg"
import { Link } from "gatsby"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="3"
    title="Crează un cont nou"
    description="Pentru a crea un cont nou folosește adresa ta de email și datele personale apoi confirmă emailul primit."
    buttonLink="/salut/locDeConsumQuest"
  >
    <img src={STEP_THREE_CONTENT} alt="App Store" style={{ width: "100%" }} />
    <div style={{ marginTop: 16, fontSize: 16 }}>
      <div style={{ fontSize: 16, marginBottom: 8 }}>
        1. Crează un cont nou accesând pagina <a>app.myara.ro</a>
      </div>
      <div style={{ marginBottom: 8 }}>
        2. Pentru crea un cont nou va trebui să accesezi butonul{" "}
        <b>Înregistrează-te.</b>
      </div>
      <div style={{ marginBottom: 8 }}>
        3. Formularul de înregistrare necesită datele tale personale și de
        contact și o parolă pentru securitatea contului tău.
      </div>
      <div>
        4. După completarea cu succes a datelor în formular, vei primi un email
        care conține un link de activare. Pentru a activa contul va trebui să
        urmezi acest link.
      </div>
    </div>
    <div style={{ marginTop: 16 }}>
      <Link to="https://app.myara.ro" style={{ marginTop: 6 }}>
        <button
          style={{
            width: "100%",
            backgroundColor: "#fff",
            height: 48,
            border: "1px solid #ABB2BA",
            cursor: "pointer",
            color: "#2E3338",
            borderRadius: 8,
            fontSize: 16,
            fontWeight: 400,
            marginBottom: 16,
            fontFamily: "Inter",
          }}
        >
          Creaza cont
        </button>
      </Link>
    </div>
    <span
      style={{
        fontSize: 12,
        lineHeight: "20px",
        fontFamily: "Inter",
      }}
    >
      Se va deschide o fereastră nouă cu formularul de înregistrare.
    </span>
  </TutorialLayout>
)

export default Second
